import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { get, post, remove } from '../../api';
import {
  TChainAddItem,
  TChainByUserResponse,
  TChainListResponse,
  TRegionByChain,
  TUseAddChains,
  TUseAddRegion,
  TUseChainList,
  TUseChains,
} from './ChainService.props';

export const useChain = ({ userId, email, country, page, size }: TUseChains) => {
  const queryClient = useQueryClient();

  const paramsByRequest = `chainsByUser?email=${email}`;
  return useQuery<TChainByUserResponse>({
    queryKey: ['chains', userId, country, page, size],
    queryFn: ({ signal }) =>
      get({
        url: `api/link-customer-list-service/chain/${paramsByRequest}&user_id=${userId}&page=${page}&size=${size}`,
        config: {
          signal,
          headers: {
            country,
            userId,
          },
        },
      }),
    onError: () => {
      queryClient.setQueryData(['chains', userId, country], {
        chains: [],
      });
    },
    keepPreviousData: false,
  });
};

export const useChainList = ({ page, size, term, country, userId, enabled }: TUseChainList) => {
  const queryClient = useQueryClient();

  const customParams = term.length > 0 ? `&term=${term}` : '';
  return useQuery<TChainListResponse>({
    queryKey: ['chains-list', userId, page, size, term],
    queryFn: ({ signal }) =>
      get({
        url: `api/link-customer-list-service/chain?user_id=${userId}&page=${page}&size=${size}${customParams}`,
        config: {
          signal,
          headers: {
            country,
            userId,
          },
        },
      }),
    enabled,
    staleTime: 0,
    keepPreviousData: false,
  });
};

export const useAddChains = ({ userId, country }: TUseAddChains) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['add-chain'],
    mutationFn: (chains: TChainAddItem[]) =>
      post({
        url: `api/link-customer-list-service/chain/chainsByUser/${userId}`,
        body: chains,
        config: {
          headers: {
            country,
            userId,
          },
        },
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['add-chain']);
    },
  });
};

export const useDeleteChains = ({ chainId, userId, country }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['delete-chain'],
    mutationFn: () =>
      remove({
        url: `api/link-customer-list-service/chain/chainsByUser/${userId}/${chainId}`,
        config: {
          headers: {
            country,
            userId,
          },
        },
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['chains']);
    },
  });
};

export const useRegionByChain = ({ userId, country, email, page, size }) => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ['region-by-chain', userId, country, email, page, size],
    queryFn: ({ signal }) =>
      get({
        url: `api/link-customer-list-service/chain/chainsByUser?email=${email}&user_id=${userId}&page=${page}&size=${size}`,
        config: {
          signal,
          headers: {
            country,
            userId,
          },
        },
      }),
    enabled: true,
    staleTime: 0,
    keepPreviousData: false,
  });
};

export const useAddRegion = ({ userId, vendorId, country }: TUseAddRegion) => {
  return useMutation({
    mutationKey: ['add-region'],
    mutationFn: (RegionByChain: TRegionByChain[]) =>
      post({
        url: `api/link-customer-list-service/chain/regionByChain?vendorId=${vendorId}&userId=${userId}`,
        body: RegionByChain,
        config: {
          headers: {
            country,
          },
        },
      }),
  });
};
