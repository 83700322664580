export const paginationNormalizer = ({
  totalElements,
  currentPage,
  pageSize,
  onChange,
  showPageSizeSelector = true,
}) => {
  return {
    current: currentPage,
    onChange: typeof onChange === 'function' ? onChange : () => null,
    pageSize,
    pageSizeOptions: [10, 15, 20, 25],
    pageSizeOptionsIntl: (number: number) => `Lines per page - ${number}`,
    quantityIndicatorIntl: 'of',
    showPageSizeSelector,
    showQuantityIndicator: true,
    total: totalElements ?? 0,
  };
};
