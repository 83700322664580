import { DEFAULT_INITIAL_PAGE, DEFAULT_INITIAL_PAGE_SIZE } from '@constants';
import { createContext, useCallback, useContext, useState } from 'react';
import { paginationNormalizer } from 'utils/paginationNormalizer';

interface ITableContext {
  tablePage: number;
  onChangePagination: (onChangedPage: () => void, onChangedPageSize: () => void) => void;
  pageSize: number;
  setTablePage: React.Dispatch<React.SetStateAction<number>>;
  createPagination: (
    currentPage: number,
    pageSize: number,
    totalElements: number,
    onChange: (onChangedPage: () => void, onChangedPageSize: () => void) => void,
    showPageSizeSelector: boolean
  ) =>
    | {
        current: number;
        onChange: () => void;
        pageSize: number;
        pageSizeOptions: number[];
        pageSizeOptionsIntl: (number: number) => string;
        quantityIndicatorIntl: string;
        showPageSizeSelector: boolean;
        showQuantityIndicator: boolean;
        total: number;
      }
    | undefined;
}

const TableContext = createContext({} as ITableContext);

const TableProvider = ({ children }) => {
  const [tablePage, setTablePage] = useState(DEFAULT_INITIAL_PAGE);

  const [pageSize, setPageSize] = useState(DEFAULT_INITIAL_PAGE_SIZE);

  /* istanbul ignore next */
  const createPaginationHandler = (setPage, setPageSize) => (onChangedPage, onChangedPageSize) => {
    setPage(onChangedPage - 1);
    setPageSize(onChangedPageSize);
  };
  const onChangePagination = createPaginationHandler(setTablePage, setPageSize);

  const createPagination = useCallback<ITableContext['createPagination']>((...args) => {
    const [currentPage, pageSize, totalElements, onChange, showPageSizeSelector] = args;

    return paginationNormalizer({
      currentPage: currentPage + 1,
      totalElements,
      pageSize,
      onChange,
      showPageSizeSelector,
    });
  }, []);

  return (
    <TableContext.Provider
      value={{
        tablePage,
        createPagination,
        onChangePagination,
        pageSize,
        setTablePage,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};

const useTable = () => {
  return useContext(TableContext);
};

export { TableContext, TableProvider, useTable };
