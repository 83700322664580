import { AppHeaderConfigPayload } from 'admin-portal-shared-services';
import { MessageDescriptor } from 'react-intl';
import { Modules } from 'routes/Definition';

interface ICustomer {
  name: string;
  email: string;
  id: string;
  countryCode: string;
}

export const getAppHeaderConfig: (
  pageName: Modules,
  formatMessage: (descriptor: MessageDescriptor) => string,
  selectedCustomer?: ICustomer
) => Promise<AppHeaderConfigPayload> = async (pageName, formatMessage, selectedCustomer) => {
  const { homePath, customerListPath, kamListPath, kamCustomerListPath, kamCustomerAddChainPath } =
    await import('routes/Definition');

  switch (pageName) {
    case 'CUSTOMER_LIST':
      return {
        pageTitle: ' ',
        countrySelect: true,
        breadcrumbConfig: {
          homePath,
          items: getItemsCustomerList(formatMessage, customerListPath),
        },
      };
    case 'KAM_LIST_PAGE':
      return {
        pageTitle: formatMessage({ id: 'pages.kamList.title' }),
        countrySelect: true,
        breadcrumbConfig: {
          homePath,
          items: getItemsKAMList(formatMessage, customerListPath, kamListPath),
        },
      };
    case 'KAM_CUSTOMER_LIST_PAGE':
      return {
        pageTitle: ' ',
        countrySelect: false,
        breadcrumbConfig: {
          homePath,
          items: getItemsKamCustomerList(
            selectedCustomer,
            formatMessage,
            customerListPath,
            kamListPath,
            kamCustomerListPath
          ),
        },
      };

    case 'KAM_CUSTOMER_ADD_CHAIN_PAGE':
      return {
        pageTitle: ' ',
        countrySelect: true,
        breadcrumbConfig: {
          homePath,
          items: getItemsAddChain(
            selectedCustomer,
            formatMessage,
            customerListPath,
            kamListPath,
            kamCustomerListPath,
            kamCustomerAddChainPath
          ),
        },
      };

    case 'KAM_CUSTOMER_ADD_REGION_PAGE':
      return {
        pageTitle: ' ',
        countrySelect: true,
        breadcrumbConfig: {
          homePath,
          items: getItemsAddChain(
            selectedCustomer,
            formatMessage,
            customerListPath,
            kamListPath,
            kamCustomerListPath,
            kamCustomerAddChainPath
          ),
        },
      };
  }
};

const getItemsCustomerList = (
  formatMessage: (descriptor: MessageDescriptor) => string,
  ...paths: string[]
) => [
  {
    label: formatMessage({ id: 'breadcrumb.customerList.title' }),
    path: paths[0],
    isCurrentPage: true,
  },
];

const getItemsKAMList = (
  formatMessage: (descriptor: MessageDescriptor) => string,
  ...paths: string[]
) => [
  {
    label: formatMessage({ id: 'breadcrumb.customerList.title' }),
    path: paths[0],
    isCurrentPage: false,
  },
  {
    label: formatMessage({ id: 'breadcrumb.kamList.title' }),
    path: paths[1],
    isCurrentPage: true,
  },
];

const getItemsKamCustomerList = (
  selectedCustomer: ICustomer,
  formatMessage: (descriptor: MessageDescriptor) => string,
  ...paths: string[]
) => [
  {
    label: formatMessage({ id: 'breadcrumb.customerList.title' }),
    path: paths[0],
    isCurrentPage: false,
  },
  {
    label: formatMessage({ id: 'breadcrumb.kamList.title' }),
    path: paths[1],
    isCurrentPage: false,
  },
  {
    label: selectedCustomer.name,
    path: paths[2],
    isCurrentPage: true,
  },
];

const getItemsAddChain = (
  selectedCustomer: ICustomer,
  formatMessage: (descriptor: MessageDescriptor) => string,
  ...paths: string[]
) => [
  {
    label: formatMessage({ id: 'breadcrumb.customerList.title' }),
    path: paths[0],
    isCurrentPage: false,
  },
  {
    label: formatMessage({ id: 'breadcrumb.kamList.title' }),
    path: paths[1],
    isCurrentPage: false,
  },
  {
    label: selectedCustomer.name,
    path: paths[2],
    isCurrentPage: false,
  },
  {
    label: formatMessage({ id: 'breadcrumb.kamCustomerAddChain.title' }),
    path: paths[3],
    isCurrentPage: true,
  },
];
